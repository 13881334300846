<template name="component-name">
  <v-text-field
    v-if="!rut"
    :style="cssVars"
    class="labelStyle"
    :label="label"
    :placeholder="placeholder"
    :hide-details="hide"
    height="30"
    dense
    outlined
    v-model="inputVal"
    :background-color="readonly === 'true' ? '#F2F2F2' : 'none'"
    v-bind:disabled="readonly === 'true' ? true : false"
    :prepend-inner-icon="icon"
    :rules="rules"
    :maxlength="max_length"
    :type="type"
    :prefix="price ? '$' : ''"
    :suffix="weight ? 'Kgs' : ''"
    :error-messages="error"
  ></v-text-field>
  <v-text-field
    dense
    v-else
    :style="cssVars"
    class="labelStyle"
    :label="label"
    :placeholder="placeholder"
    :hide-details="hide"
    height="30"
    outlined
    v-model="inputVal"
    :background-color="readonly === 'true' ? '#F2F2F2' : 'none'"
    v-bind:disabled="readonly === 'true' ? true : false"
    :prepend-inner-icon="icon"
    :rules="[rules, isValid]"
    :type="type"
    :maxlength="max_length"
  ></v-text-field>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "inputtext",
  data: () => ({
    masks: ["#[#.###.###-N]"],
  }),
  props: [
    "label",
    "placeholder",
    "model",
    "max_length",
    "color",
    "detail_color",
    "readonly",
    "hide",
    "icon",
    "rut",
    "type",
    "weight",
    "price",
    "error",
  ],

  computed: {
    ...mapGetters("Users", ["getBotonColor"]),
    cssVars() {
      return {
        "--bg-color": this.inputVal === "" ? "#BEBEBE" : "#A9A9A9",
        "--bg-color-text": this.inputVal === "" ? "#A0A0A0" : "#A0A0A0",
        "--detail-color": this.detail_color,
      };
    },

    btnColor() {
      return this.getBotonColor;
    },

    inputVal: {
      get() {
        if (this.rut && this.model !== "") {
          return this.format(this.model);
        } else return this.model;
      },
      set(val) {
        if (this.readonly === "false") {
          if (this.rut) {
            this.$emit("input", this.format(val));
          } else {
            this.$emit("input", val);
          }
        }
      },
    },
  },
  methods: {
    clean(rut) {
      return typeof rut === "string"
        ? rut.replace(/^0+|[^0-9kK]+/g, "").toUpperCase()
        : "";
    },

    format(rut) {
      rut = this.clean(rut);

      let result = rut.slice(-4, -1) + "-" + rut.substr(rut.length - 1);
      for (let i = 4; i < rut.length; i += 3) {
        result = rut.slice(-3 - i, -i) + "." + result;
      }

      return result;
    },
    isValid(value) {
      var valor = value.replace(/[^A-Z0-9]/gi, "");

      var cuerpo = valor.slice(0, -1);
      var dv = valor.slice(-1).toUpperCase();

      var suma = 0;
      var multiplo = 2;

      for (var i = 1; i <= cuerpo.length; i++) {
        var index = multiplo * valor.charAt(cuerpo.length - i);

        suma = suma + index;

        if (multiplo < 7) {
          multiplo = multiplo + 1;
        } else {
          multiplo = 2;
        }
      }

      var dvEsperado = 11 - (suma % 11);

      // Casos Especiales (0 y K)
      dv = dv == "K" ? 10 : dv;
      dv = dv == 0 ? 11 : dv;

      // Validar que el Cuerpo coincide con su Dígito Verificador

      if (dvEsperado != dv) {
        return "Rut inválido";
      } else {
        return null;
      }
    },
  },
};
</script>
<style scoped>
:deep .v-icon.v-icon {
  align-items: center;
  display: inline-flex;
  font-feature-settings: "liga";
  font-size: 21px;
  justify-content: center;
  letter-spacing: normal;
  line-height: 1;
  position: relative;
  text-indent: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
:deep .v-text-field.v-input--dense .v-label {
  top: 4px;
  font-size: 12px !important;
  font-family: "Inter";
  font-weight: 500;
}
.v-text-field >>> input {
  flex: 1 1 auto;
  line-height: 20px;
  padding: 8px 0 8px;
  max-width: 100%;
  min-width: 0px;
  width: 100%;
  font-size: 12px !important;
  font-family: Inter !important;
  color: #050c42 !important;
  background-color: transparent !important;
  border: 5px;
}

.v-text-field >>> label {
  font-family: Inter;
  color: #050c42;
  font-size: 12px;
  font-weight: 500;
}

.v-text-field >>> .v-text-field__details {
  font-family: Inter;
  color: red;
  font-size: 12px;
  font-weight: 500;
}
:deep.v-input--dense > .v-input__control > .v-input__slot {
  margin-bottom: 0px !important;
}
.v-input:hover >>> label {
  color: var(--bg-color-text) !important;
}
.v-input >>> label {
  color: var(--bg-color-text) !important;
}
:deep .v-text-field:hover fieldset,
.v-text-field.v-input--has-state fieldset {
  border: 1px solid var(--bg-color) !important;
}

:deep.v-text-field:hover fieldset,
.v-text-field.v-input--has-state fieldset {
  border: 1px solid var(--bg-color);
}
:deep.v-text-field fieldset,
.v-text-field.v-input--has-state fieldset {
  border: 1px solid var(--bg-color);
}

.v-input {
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  font-size: 16px;
  letter-spacing: normal;
  max-width: 100%;
  text-align: left;
  background-color: white !important;
}
.bg {
  background-color: var(--bg-color) !important;
}
:deep.v-text-field--full-width .v-input__prepend-outer,
.v-text-field--full-width .v-input__prepend-inner,
.v-text-field--full-width .v-input__append-inner,
.v-text-field--full-width .v-input__append-outer,
.v-text-field--enclosed .v-input__prepend-outer,
.v-text-field--enclosed .v-input__prepend-inner,
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  margin-top: 17px;
  margin-left: 10px !important;
}

:deep .v-input__control .v-text-field__details {
  padding-top: 5px;
  background-color: var(--detail-color) !important;
  margin-bottom: 0px;
}
:deep
  .v-input__control
  .v-text-field.v-text-field--enclosed
  .v-text-field__details {
  padding-top: 0px;
  margin-bottom: 0px !important;
}

:deep .v-input--is-disabled.v-text-field--outline .v-input__slot {
  border: 0px solid red;
}

:deep .v-input--is-disabled.v-text-field--outline input {
  color: red !important;
}
:deep .v-text-field__prefix {
  text-align: right;
  padding-right: 4px;
  font-size: 12px !important;
  color: #050c42;
}
:deep .v-text-field__suffix {
  align-self: center;
  cursor: default;
  transition: color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  font-size: 12px;
  color: #050c42;
}
.v-text-field:hover >>> .v-text-field__suffix {
  align-self: center;
  cursor: default;
  transition: color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  font-size: 12px;
  color: var(--bg-color);
}
.v-text-field:hover >>> .v-text-field__prefix {
  text-align: right;
  padding-right: 4px;
  font-size: 12px !important;
  color: var(--bg-color);
}
</style>
